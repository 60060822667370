<template>
  <div
    :ref="refId"
    class="select"
    :class="[
      {'select_open': isOpen,
       'select_disabled': settings.disable},
      {'select_list': settings.showImageInput && inputValueArray.length},
      settings.selectClass
    ]"
    @click="handleClick"
  >
    <template>
      <i
        ref="close"
        class="ub-icon-arrow select__icon"
        :class="{'select__icon_up': isOpen}"
      >
      </i>
      <div
        class="select__wrapper"
      >
        <VuePerfectScrollbar
          ref="selectList"
          class="select-list"
          :class="[
            {'select-list_active': isOpen},
            {'select-list_offset': inputValueArray.length},
            {'select-list_column': settings.columnList}
          ]"
        >
          <div
            v-for="(item, index) in inputValueArray"
            :key="item.key"
            class="select-list__item"
          >
            <span
              v-if="item.image && getOptionImage(item.image)"
              class="select-list__item-img"
            >
              <img
                :src="getOptionImage(item.image)"
                :alt="item.text"
              >
            </span>
            <span class="select-list__item-text">
              {{ item.text }}
            </span>
            <span class="select-list__item-icon">
              <i
                class="ub-icon-close"
                @click.stop="removeSelectedItem(index)"
              >
              </i>
            </span>
          </div>
          <div
            class="select__search"
            :class="{'select__search_offset': inputValueArray.length}"
            :style="{
              width: calcWidth
            }"
          >
            <input
              :ref="'selectInput-' + refId"
              v-model="inputText"
              type="text"
              class="select__input"
              :tabindex="settings.tabindex"
              :placeholder="settings.placeholderText"
              :readonly="readOnly"
              :class="{'select__input_opacity': inputValueArray.length}"
              @keydown.stop="selectInputKeyDown($event)"
            >
          </div>
        </VuePerfectScrollbar>
      </div>
    </template>
    <VuePerfectScrollbar
      v-if="computedOptionsList.length"
      ref="drop"
      infiniteWrapper
      :style="popupPosition"
      class="select__drop"
      :class="[
        {'select__drop_top': settings.toTop},
        {'select__drop_list': settings.showImageInput && inputValueArray.length}
      ]"
      @mouseleave="leaveBlock()"
    >
      <div class="select__drop-list">
        <div
          v-for="(option, index) in computedOptionsList"
          :key="option.key"
          :class="[
            {'select__drop-list_image': settings.showImageInput},
            {'select__drop-link_checked': checkedOptionStatus(option.key)}
          ]"
        >
          <template v-if="index === 0">
            <a
              :ref="`select__drop-link_first-${refId}`"
              href="javascript:void(0)"
              class="select__drop-link"
              :class="{'select__drop-link_checked': checkedOptionStatus(option.key)}"
              @keydown.prevent.stop="selectDropLinkKeyDown($event, option.key)"
              @click.stop="updateSelect(option.key, checkedOptionStatus(option.key))"
            >
              <span
                v-if="settings.withImage"
                class="select__img select__img_drop"
              >
                <template v-if="option.img !== null">
                  <img
                    :src="option.img"
                    alt=""
                  >
                </template>
                <template v-else>
                  <img
                    :src="require('@/assets/images/placeholder/list-app.svg')"
                    alt=""
                  >
                </template>
              </span>
              {{ option.text }}
              <i
                v-if="checkedOptionStatus(option.key)"
                class="ub-icon-check-mark-select select__drop-icon"
              >
              </i>
            </a>
          </template>
          <template v-else>
            <a
              href="javascript:void(0)"
              class="select__drop-link"
              :class="{'select__drop-link_checked': checkedOptionStatus(option.key)}"
              @keydown.prevent.stop="selectDropLinkKeyDown($event, option.key)"
              @click.stop="updateSelect(option.key, checkedOptionStatus(option.key))"
            >
              <span
                v-if="settings.withImage"
                class="select__img select__img_drop"
              >
                <img
                  v-if="option.img !== undefined"
                  :src="option.img"
                  alt=""
                >
                <img
                  v-else
                  :src="require('@/assets/images/placeholder/list-app.svg')"
                  alt=""
                >
              </span>
              {{ option.text }}
              <i
                v-if="checkedOptionStatus(option.key)"
                class="ub-icon-check-mark-select select__drop-icon"
              >
              </i>
            </a>
          </template>
          <i
            class="select__check"
            :class="{'select__check_checked': checkedOptionStatus(option.key)}"
          >
          </i>
          <div
            v-if="settings.showImageInput && !checkedOptionStatus(option.key)"
            class="select__check-circle"
            @click.stop="updateSelect(option.key, checkedOptionStatus(option.key))"
          ></div>
          <img
            v-if="option.image && getOptionImage(option.image)"
            class="select__drop-link-image"
            :src="getOptionImage(option.image)"
            :class="{'select__drop-link-image_left': settings.imagePosition === 'left'}"
            :alt="option.text"
          >
        </div>
      </div>
      <InfiniteLoading
        v-if="settings.infinity"
        ref="infiniteLoading"
        spinner="spiral"
        :distance="loadingStart"
        @infinite="infiniteHandler"
      >
        <span slot="no-more"></span>
        <span slot="no-results"></span>
      </InfiniteLoading>
    </VuePerfectScrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import {
  find, filter, includes, sortBy, indexOf,
} from 'lodash';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'MultipleSelectTemplate',
  components: {
    VuePerfectScrollbar,
    InfiniteLoading,
  },
  props: ['settings'],
  data() {
    return {
      popupPosition: {},
      isOpen: false,
      value: '',
      refId: Math.floor(new Date().valueOf() * Math.random()),
      searchValue: '',
      inputValueStr: '',
      inputValueArray: [],
      selectInputKeyDownStatus: false,
      sortingOptionsListDef: this.settings.optionList,
      inputWidth: 100,
      loadingStart: 350,
    };
  },
  computed: {
    calcWidth() {
      if (this.inputWidth === 100) {
        return `${100}%`;
      }
      return `${this.inputWidth}px`;
    },
    readOnly() {
      const { readOnly = false } = this.settings;
      return readOnly || !this.isOpen;
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(data) {
        this.value = data;
      },
    },
    inputText: {
      get() {
        return this.inputValueStr;
      },
      set(data) {
        this.inputValueStr = data;
        this.searchValue = data;
      },
    },
    sortingOptionsList: {
      get() {
        return this.sortingOptionsListDef;
      },
      set(data) {
        this.sortingOptionsListDef = data;
      },
    },
    computedOptionsList() {
      const arr = [];
      const listArr = this.settings.sorting ? this.sortingOptionsList : this.settings.optionList;
      if (this.isOpen) {
        const { optionList = [] } = this.settings;
        if (optionList.length) {
          arr.push(...optionList.filter((obj) => {
            const { text = '' } = obj;
            return includes(
              text.toLowerCase(), this.searchValue.toLowerCase(),
            );
          }));
        }
      } else {
        arr.push(...listArr);
      }
      return arr;
    },
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        if (this.settings.infinity) this.$refs.infiniteLoading.stateChanger.reset();
        this.searchValue = '';
        this.$refs[`selectInput-${this.refId}`].focus();
      } else {
        const modal = document.querySelector('.admin-modal');
        if (modal !== null) {
          modal.addEventListener('click', this.closeSelect);
        }
        document.addEventListener('click', this.closeSelect);
        const el = find(this.computedOptionsList, (obj) => obj.key === this.inputValue);
        if (el === undefined) {
          this.inputText = '';
          if (this.settings.textCode) {
            this.inputKey = '';
          }
        } else {
          this.inputText = el.text;
          if (this.settings.textCode) {
            this.inputKey = el.key;
          }
        }
      }
    },
    'settings.selectedKey': function (newVal) {
      this.inputValue = [];
      this.inputValueArray = [];
      newVal.forEach((elem) => {
        const el = find(this.computedOptionsList, (obj) => obj.key === elem);
        if (el !== undefined) {
          this.inputValue.push(elem);
          if (el.key !== 'allKey') {
            this.inputValueArray.push(el);
          }
        }
      });
    },
    'settings.optionList': function (newVal) {
      this.sortingOptionsList = newVal;
    },
  },
  beforeDestroy() {
    const modal = document.querySelector('.admin-modal');
    if (modal !== null) {
      modal.removeEventListener('click', this.closeSelect);
    }
    document.removeEventListener('click', this.closeSelect);
  },
  mounted() {
    this.value = JSON.parse(JSON.stringify(this.settings.selectedKey));
    const modal = document.querySelector('.admin-modal');
    if (modal !== null) {
      modal.addEventListener('click', this.closeSelect);
    }
    document.addEventListener('click', this.closeSelect);
    this.inputValue = [];
    this.inputValueArray = [];
    if (this.settings.selectedKey !== '') {
      this.settings.selectedKey.forEach((elem) => {
        const el = find(this.computedOptionsList, (obj) => obj.key === elem);
        if (el !== undefined) {
          this.inputValue.push(elem);
          this.inputValueArray.push(el);
        }
      });
    }
    if (this.inputValue.length) {
      this.inputWidth = 10;
    } else {
      this.inputWidth = 100;
    }
    this.inputText = '';
  },
  methods: {
    leaveBlock() {
      this.isOpen = false;
      this.$refs[`selectInput-${this.refId}`].blur();
    },
    removeSelectedItem(index) {
      this.inputValueArray.splice(index, 1);
      this.inputValue.splice(index, 1);
      if (!this.inputValueArray.length) {
        this.inputWidth = 100;
      }
      this.$emit('changeSelect', this.inputValue);
    },
    getOptionImage(image) {
      try {
        /* eslint-disable */
        return require(`@/assets/images/${image}`);
        /* eslint-enable */
      } catch (e) {
        return false;
      }
    },
    infiniteHandler($state) {
      this.$emit('infiniteHandler', $state);
    },
    selectDropLinkKeyDown(e, key) {
      switch (e.keyCode) {
      case 13:
        e.preventDefault();
        e.stopPropagation();
        this.updateSelect(key, this.checkedOptionStatus(key));
        setTimeout(() => {
          e.target.classList.add('select__drop-link_hover');
        }, 0);
        break;
      case 27:
        e.target.classList.remove('select__drop-link_hover');
        this.$refs[`selectInput-${this.refId}`].focus();
        this.isOpen = false;
        break;
      case 38:
        if (e.target.parentNode.previousSibling !== null) {
          e.target.classList.remove('select__drop-link_hover');
          e.target.parentNode.previousSibling.firstChild.classList.add('select__drop-link_hover');
          e.target.parentNode.previousSibling.firstChild.focus();
        } else {
          this.$refs[`selectInput-${this.refId}`].focus();
          e.target.parentNode.firstChild.classList.remove('select__drop-link_hover');
        }
        break;
      case 40:
        if (e.target.parentNode.nextSibling !== null) {
          e.target.classList.remove('select__drop-link_hover');
          e.target.parentNode.nextSibling.firstChild.classList.add('select__drop-link_hover');
          e.target.parentNode.nextSibling.firstChild.focus();
        }
        break;
      default:
        this.$refs[`selectInput-${this.refId}`].focus();
        break;
      }
    },
    selectInputKeyDown(e) {
      const { value } = e.target;
      if (this.inputValueArray.length) {
        this.inputWidth = 10;
      }
      this.inputWidth = value.length > 0 ? (value.length + 1) * 10 : 10;
      if (!this.inputValueArray.length) {
        this.inputWidth = 100;
      }
      switch (e.keyCode) {
      case 8:
        if (this.searchValue === '' && this.inputValueArray.length) {
          this.inputValue.pop();
          this.inputValueArray.pop();
          if (!this.inputValueArray.length) {
            this.inputWidth = 100;
          } else {
            this.inputWidth = 10;
          }
          this.$emit('changeSelect', this.inputValue);
        }
        break;
      case 9:
        this.isOpen = false;
        break;
      case 27:
        e.preventDefault();
        break;
      case 38: {
        e.preventDefault();
        break;
      }
      case 40:
        e.preventDefault();
        this.isOpen = true;
        this.$refs[`select__drop-link_first-${this.refId}`][0].focus();
        this.$refs[`select__drop-link_first-${this.refId}`][0].classList.add('select__drop-link_hover');
        break;
      default:
        if (!this.isOpen) this.isOpen = true;
        break;
      }
    },
    handleClick() {
      if (this.settings.sorting && !this.isOpen) {
        this.settings.selectedKey.forEach((item) => {
          this.sortingOptionsList = sortBy(this.sortingOptionsList, (obj) => (obj.key === item) * -1);
        });
      }
      if (this.settings.disable !== undefined) {
        if (!this.settings.disable) {
          this.isOpen = !this.isOpen;
        }
      } else {
        this.isOpen = !this.isOpen;
      }
      this.$nextTick(() => {
        const { toTopFixed } = this.settings;
        if (this.isOpen && toTopFixed) {
          const { top, left, width } = this.$refs.selectList.$el.getBoundingClientRect();
          const { height } = this.$refs.drop.$el.getBoundingClientRect();
          this.popupPosition = {
            position: 'fixed!important',
            top: `${top - height - 14}px!important`,
            right: 'auto',
            left: `${left}px`,
            width: `${width}px`,
          };
        }
      });
    },
    closeSelect(e) {
      if (this.$refs[this.refId] !== undefined) {
        if (!this.$refs[this.refId].contains(e.target)) {
          this.isOpen = false;
        }
      }
    },
    updateSelect(key, status) {
      if (key === 'allKey') {
        this.inputValue = [];
        this.inputValueArray = [];
        const el = find(this.computedOptionsList, (obj) => obj.key === key);
        if (el !== undefined) {
          const allList = filter(this.computedOptionsList, (obj) => obj.key !== key);
          if (!status) {
            this.inputValue.push(key);
            allList.forEach((elem) => {
              this.inputValue.push(elem.key);
              this.inputValueArray.push(elem);
            });
          }
          if (this.inputValueArray.length === this.settings.optionList.length) {
            this.inputText = this.$t('servicesPage.addServiceModal.allSelected');
          } else {
            this.inputText = this.inputValueArray.map((item) => item.text).join(', ');
          }
        }
      } else {
        const el = find(this.computedOptionsList, (obj) => obj.key === key);
        if (el !== undefined) {
          if (!status) {
            this.inputValue.push(key);
            this.inputValueArray.push(el);
          } else {
            const elAllKeyIndex = indexOf(this.inputValue, find(this.inputValue, (obj) => obj === 'allKey'));
            if (elAllKeyIndex !== -1) {
              this.inputValue.splice(elAllKeyIndex, 1);
            }
            this.inputValue.splice(this.inputValue.indexOf(key), 1);
            this.inputValueArray.splice(this.inputValue.indexOf(key), 1);
          }
          if (this.inputValueArray.length === this.settings.optionList.length) {
            this.inputText = this.$t('servicesPage.addServiceModal.allSelected');
          } else {
            this.inputText = this.inputValueArray.map((item) => item.text).join(', ');
          }
        }
      }
      if (!this.inputValueArray.length) {
        this.inputWidth = 100;
      } else {
        this.inputWidth = 10;
      }
      this.$emit('changeSelect', this.inputValue);
      this.searchValue = '';
      this.inputText = '';
      this.$refs[`selectInput-${this.refId}`].focus();
    },
    checkedOptionStatus(key) {
      const check = find(this.inputValue, (obj) => obj === key);
      if (check !== undefined) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../sass/variables";

.select {
  position: relative;
  min-height: 40px;
  background: $color-white;
  cursor: pointer;
  font: 14px $font-global;
  color: $color-dodger-blue;
  flex-grow: 1;
  transition: background-color .2s ease-in;
  user-select: none;
  border-radius: $borderRadius;

  &__drop {
    top: auto !important;
  }

  &:before {
    content: '';
    height: 10px;
    bottom: -10px;
    left: 0;
    width: 100%;
    position: absolute;
    background: transparent;
  }

  &-list {
    width: 100%;
    border: none;
    align-items: center;
    padding: 0 30px 0 12px!important;

    &__item {
      margin: 6px;
    }
  }

  &__wrapper {
    display: flex;
    min-height: 40px;
    transition: border-color .15s ease-in;

    &:hover {
      border-color: $color-dodger-blue;
    }

    input {
      min-width: 4px;
      width: 100%;
      height: 28px;
      padding: 0 6px;
      border: none;
    }
  }

  &__search {
    &_offset {
      margin-left: 6px;
    }
  }

  &__input {
    &_opacity {
      &::placeholder {
        opacity: 0 !important;
      }
    }
  }

  &_list {
    height: auto;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    border: 1px solid $color-gallery;
    border-radius: $borderRadius;
    padding: 6px 8px 1px;
    transition: border-color .15s ease-in;
    overflow: hidden;
    max-height: 200px;

    &_active {
      border: 1px solid $color-dodger-blue;
    }

    &_offset {
      padding-left: 6px !important;
    }

    &_column {
      flex-direction: column;
      align-items: flex-start;
    }

    &__item {
      margin-right: 6px;
      margin-bottom: 6px;
      border: 1px solid $color-gallery;
      padding: 3px 8px;
      display: flex;
      align-items: center;
      min-height: 25px;
      font-size: $font-size-landing-small;

      &-icon {
        color: $color-gallery;
        font-size: $font-size-icon;
        margin-left: 10px;
        transition: color .15s ease-in;

        &:hover {
          color: $color-black;
        }
      }

      &-img {
        width: 16px;
        height: 16px;
        margin-right: 6px;

        img {
          border-radius: 50%;
        }
      }

      &-text {
        color: $color-black;
      }
    }
  }

  &_alt {
    width: 100%;
  }

  &.error  {
    .select-list {
      border: 1px solid $color-cardinal;
    }
  }

  &__icon {
    z-index: 0;
    right: 10px;
    top: 15px;
    font-size: $font-size-sm;
    position: absolute;

    &_up {
      transform: rotate(180deg);
    }
  }

  .ps-container {
    height: auto;
    max-height: 327px;
  }

  &_disabled{
    cursor: default;
    background-color: $color-alabaster;
    pointer-events: none;

    .select__input {
      cursor: default;
      border: 1px solid $color-alabaster;
      background: $color-alabaster;
    }

    .select__icon {
      display: none;
    }
  }

  &:hover, &_open{

    &:before{
      z-index: 6;
    }

    .select__input {
      border-color: $color-dodger-blue;
    }

  }

  &_open {
    z-index: 20!important;

    .select__drop {
      display: block;
    }
  }

  &__input{
    width: 100%;
    border: 1px solid $color-gallery;
    color: $color-black;
    padding: 10px 40px 10px 10px;
    height: 40px;
    font: $font-size-base $font-global;
    cursor: pointer;
    font-size: inherit;
    transition: none;
    border-radius: $borderRadius;

    &:focus {
      border-color: $color-dodger-blue;
    }

    &_image {
      padding-left: 38px;
    }

    &::-webkit-input-placeholder {
      font-family: $font-global;
      text-shadow: none !important;
      color: $color-silver-chalice;
    }
    &::-moz-placeholder {
      font-family: $font-global;
      text-shadow: none !important;
      color: $color-silver-chalice;
    }
    &:-moz-placeholder {
      font-family: $font-global;
      text-shadow: none !important;
      color: $color-silver-chalice;
    }
    &:-ms-input-placeholder {
      font-family: $font-global;
      text-shadow: none !important;
      color: $color-silver-chalice;
    }

    &:focus {
      color: $color-black!important;

      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-moz-placeholder,
      &:-ms-input-placeholder {
        opacity: 0;
        color: $color-black!important;
      }
    }
  }

  &__drop {
    display: none;
    position: absolute !important;
    top: 35px;
    left: 0;
    z-index: $z-index-big;
    width: 100%;
    margin-top: 10px;
    overflow: hidden;
    padding: 4px 0;
    background: $color-white;
    color: $color-dodger-blue;
    box-shadow: 0 0 4px rgba($color-black, .25);
    border-radius: $borderRadius;

    &_list {
      top: auto!important;
    }

    &-icon {
      position: absolute;
      right: 14px;
      color: $color-dodger-blue;
      font-size: $font-size-xlg;
    }

    &_top {
      bottom: 100%;
      margin-bottom: 2px;
      top: auto;
    }

    &_bottom {
      bottom: 0;
      top: auto;
    }

    &-link {
      padding: 10px 13px;
      text-decoration: none;
      display: flex;
      align-items: center;
      color: $color-black;
      height: 46px;
      transition: color 200ms ease-in;
      font: 14px $font-global;
      border-bottom: 1px solid $color-alabaster;

      span {
        margin-left: 6px;
      }

      &_checked {
        position: relative;
        border-color: transparent;
        font-family: $font-global-medium;
      }

      &:hover {

        &:before, &:after {
          display: none;
        }
      }

      &-image {
        width: 20px;
        height: 20px;
        object-fit: cover;
        border-radius: 50%;

        &_left {
          order: -1;
        }
      }
    }

    &-list > div {
      position: relative;

      a {
        width: 100%;
      }

      &:hover {
        a {
          background: $color-alabaster!important;
        }
      }
    }
    &-list {
      max-height: 180px;

      &_image {
        display: flex;
        align-items: center;
        transition: background-color .15s ease-in;
        padding-left: 10px;

        &:hover {
          background: $color-alabaster;

          a {
            background: none !important;
          }
        }
      }
    }
  }

  &__check {
    position: absolute;
    display: none;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: $font-size-small;
    color: $color-dodger-blue;

    &-icon {
      position: absolute;
      right: 12px;
      font-size: $font-size-xlg;
    }

    &-circle {
      position: absolute;
      right: 16px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid $color-dodger-blue;
    }

    &_checked {
      display: block;
    }
  }
}

.select__drop > div:hover a {
  background-color: transparent;
}

.ps-container.select__drop .ps__scrollbar-y-rail {
  right: 4px!important;
}

.select__drop-list > div.select__drop-list_image a {
  &:hover {
    background: none!important;
  }
}

.select__input {
  padding: 0!important;
}

</style>
